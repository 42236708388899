import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import asyncComponent from "./commmon/AsyncComp";
import { getPermissions, getRoles } from "./datafetch/apis/accounts";
import { updateUserInfo } from "./datafetch/apis/users";
// import Navigation from "./components/navigation/Navigation";
// import Home from "./components/home/Home";
// import NotFound from "./components/notfound/404";
// import Authentication from "./components/authentication/Authentication";
// import Articles from "./components/articlesDashboard/Articles";
// import NewArticle from "./components/createArticle/NewArticle";
// import MyEditor from "./components/editArticle/Editor";
// import ImageEditor from "./components/imagesDashboard/ImageEditor";
// import EditRedirect from "./components/editRedirect/EditRedirect";
// import PreviewArticle from "./components/previewArticle/PreviewArticle";
// import LiveBlogList from "./components/livebloglist/LiveBlogList";

const AsyncNavigation = asyncComponent(() => import("./components/navigation/Navigation"));
const AsyncHome = asyncComponent(() => import("./components/home/Home"));
const AsyncNotFound = asyncComponent(() => import("./components/notfound/404"));
const AsyncAuthentication = asyncComponent(() => import("./components/authentication/Authentication"));
const AsyncArticles = asyncComponent(() => import("./components/articlesDashboard/Articles"));
const AsyncReviewArticles = asyncComponent(() => import("./components/reviewArticlesDashboard/ReviewArticles"));
const AsyncNewArticle = asyncComponent(() => import("./components/createArticle/NewArticle"));
const AsyncNewReviewArticle = asyncComponent(() => import("./components/createReviewArticle/NewReviewArticle"));
const AsyncMyEditor = asyncComponent(() => import("./components/editArticle/Editor"));
const AsyncReviewEditor = asyncComponent(() => import("./components/editReviewArticle/ReviewEditor"));
const AsyncImageEditor = asyncComponent(() => import("./components/imagesDashboard/ImageEditor"));
const AsyncEditRedirect = asyncComponent(() => import("./components/editRedirect/EditRedirect"));
const AsyncPreviewArticle = asyncComponent(() => import("./components/previewArticle/PreviewArticle"));
const AsyncLiveBlogList = asyncComponent(() => import("./components/livebloglist/LiveBlogList"));
const DataEntry = asyncComponent(() => import("./components/dataentry/DataEntry"));
const LiveBlogEdit = asyncComponent(() => import("./components/liveBlogEdit/LiveBlogEdit"));
const AsyncLiveUpdates = asyncComponent(() => import("./components/liveupdates/LiveUpdates"));
const AsyncMenuEditor = asyncComponent(() => import("./components/editMenu/Editor"));
const AsyncNewWebpage = asyncComponent(() => import("./components/createWebpage/NewWebpage"));
const AsyncWebpageEditor = asyncComponent(() => import("./components/editWebpage/Editor"));
const AsyncWebpagesDashboard = asyncComponent(() => import("./components/webpagesDashboard/WebpagesDashboard"));
const AsyncPreviewWebpage = asyncComponent(() => import("./components/previewWebpage/PreviewWebpage"));
const AsyncVideoAdvertising = asyncComponent(() => import("./components/videoAdvertising/VideoAdvertising"));
const AsyncVideosDashboard = asyncComponent(() => import("./components/videosDashboard/VideosDashboard"));
const AsyncPopups = asyncComponent(() => import('./components/popups/Popups'));
const AsyncBanners = asyncComponent(() => import('./components/banners/Banners'));
const AsyncBillets = asyncComponent(() => import('./components/billets/Billets'));
const AsyncTopBookmakers = asyncComponent(() => import('./components/editTopBookmakers/Editor'));
const AsyncShortcodes = asyncComponent(() => import('./components/editShortcodes/Editor'));
const AsyncContentReplacementPage = asyncComponent(() => import('./components/webpageContentReplacement/ContentReplacementPage'))
const AsyncBrandblockUpdatePage = asyncComponent(() => import('./components/brandblockUpdate/BrandblockUpdatePage'))
const AsyncAccountsPage = asyncComponent(() => import('./components/accounts/Accounts'));
const AsyncRedirectsPage = asyncComponent(() => import('./components/redirects/Redirects'));
const AsyncSidebarsPage = asyncComponent(() => import('./components/sidebars/Sidebars'));
const AsyncUserAccountsPage = asyncComponent(() => import('./components/scUserAccounts/ScUserAccounts'));
const AsyncUserCommentsPage = asyncComponent(() => import('./components/scUserComments/ScUserComments'));
const AsyncAuthorsPage = asyncComponent(() => import('./components/authors/Authors'));
const AsyncVideosPage = asyncComponent(() => import('./components/videos/Videos'));
const AsyncBlogEditor = asyncComponent(() => import('./components/blogs/pages/editBlog/Editor'));
const AsyncNewBlog = asyncComponent(() => import('./components/blogs/pages/newBlog/NewBlog'));
const AsyncBlogsDashboard = asyncComponent(() => import('./components/blogs/pages/blogsDashboard/BlogsDashboard'));
const AsyncPreviewBlog = asyncComponent(() => import('./components/blogs/pages/previewBlogs/PreviewBlog'));
const AsyncPageContent = asyncComponent(() => import('./components/pageContent/PageContent'));
const AsyncTagsContent = asyncComponent(() => import('./components/tagsContent/TagsContent'));
const AsyncGiveaways = asyncComponent(() => import('./components/giveaways/Giveaways'));

const authorizedRoutes = [
  { path: "/", exact: true, component: () => <AsyncHome /> },
  { path: "/home", component: () => <AsyncHome /> },
  { path: "/authenticate", component: ({ onLogin }) => <AsyncAuthentication onLogin={onLogin} /> },
  { path: "/articles/editor", component: () => <AsyncArticles />, permission: 'allow-manage-articles' },
  { path: "/reviewArticles/editor", component: () => <AsyncReviewArticles />, permission: "allow-manage-articles" },
  { path: "/articles/new", component: () => <AsyncNewArticle />, permission: "allow-manage-articles" },
  { path: "/reviewArticles/new", component: () => <AsyncNewReviewArticle />, permission: "allow-manage-articles" },
  { path: "/editor/:id", component: ({ match }) => <AsyncMyEditor match={match} /> },
  { path: "/reviewEditor/:id", component: ({ match }) => <AsyncReviewEditor match={match} /> },
  { path: "/ImageEditor", component: ({ match }) => <AsyncImageEditor match={match} />, permission: "allow-manage-images" },
  { path: "/ImageEditor/:folder1", component: ({ match }) => <AsyncImageEditor match={match} />, permission: "allow-manage-images" },
  { path: "/ImageEditor/:folder1/:folder2", component: ({ match }) => <AsyncImageEditor match={match} />, permission: "allow-manage-images" },
  { path: "/VideosDashboard", component: ({ match }) => <AsyncVideosDashboard match={match} />, permission: "allow-manage-video" },
  { path: "/video_ads", component: ({ match }) => <AsyncVideoAdvertising match={match} />, permission: "allow-manage-video" },
  { path: "/LiveUpdates", component: ({ match }) => <AsyncLiveUpdates match={match} />, permission: "allow-manage-live-updates" },
  { path: "/LiveUpdates/:folder1", component: ({ match }) => <AsyncLiveUpdates match={match} />, permission: "allow-manage-live-updates" },
  { path: "/LiveUpdates/:folder1/:folder2", component: ({ match }) => <AsyncLiveUpdates match={match} />, permission: "allow-manage-live-updates" },
  { path: "/edit/*/*/*/*/*/*", component: ({ match }) => <AsyncEditRedirect match={match} /> },
  { path: "/preview/:id", component: ({ match }) => <AsyncPreviewArticle match={match} />, permission: "allow-manage-articles" },
  { path: "/livebloglist", component: () => <AsyncLiveBlogList />, permission: "allow-manage-live-blogs" },
  { path: "/live-blog/:id", component: ({ match }) => <LiveBlogEdit match={match} />, permission: "allow-manage-live-blogs" },
  { path: "/dataentry", component: () => <DataEntry /> },
  { path: "/webpages/new", component: () => <AsyncNewWebpage />, permission: "allow-manage-webpages" },
  { path: "/webpageEditor/:id", component: () => <AsyncWebpageEditor />, permission: "allow-manage-webpages" },
  { path: "/webpages/dashboard", component: () => <AsyncWebpagesDashboard />, permission: "allow-manage-webpages" },
  { path: "/previewWebpage/:id", component: ({ match }) => <AsyncPreviewWebpage match={match} />, permission: "allow-manage-webpages" },
  { path: "/popups", component: ({ match }) => <AsyncPopups match={match} />, permission: "allow-manage-popups" },
  { path: "/banners", component: ({ match }) => <AsyncBanners match={match} />, permission: "allow-manage-popups" },
  { path: "/billets", component: ({ match }) => <AsyncBillets match={match} />, permission: "allow-manage-billets" },
  { path: "/menu/edit", component: () => <AsyncMenuEditor />, permission: "allow-manage-menu" },
  { path: "/bookmakers/edit", component: () => <AsyncTopBookmakers />, permission: "allow-manage-bookmakers" },
  { path: "/shortcodes", component: () => <AsyncShortcodes />, permission: "allow-manage-shortcodes" },
  { path: "/webpages/content-replacement", component: () => <AsyncContentReplacementPage />, permission: "allow-webpages-content-replacement" },
  { path: "/webpages/update-brandblock-block", component: () => <AsyncBrandblockUpdatePage />, permission: "allow-webpages-update-brandblock" },
  { path: "/accounts", component: () => <AsyncAccountsPage />, permission: "allow-manage-users" },
  { path: "/users", component: () => <AsyncUserAccountsPage />, permission: "allow-manage-users" },
  { path: "/comments", component: () => <AsyncUserCommentsPage />, permission: "allow-manage-comments" },
  { path: "/redirects", component: () => <AsyncRedirectsPage />, permission: "allow-manage-page-redirects" },
  { path: "/sidebars", component: () => <AsyncSidebarsPage />, permission: "allow-manage-sidebars" },
  { path: "/authors", component: () => <AsyncAuthorsPage />, permission: "allow-manage-authors" },
  { path: "/videos", component: () => <AsyncVideosPage />, permission: "allow-manage-video" },
  { path: "/blogs/new", component: () => <AsyncNewBlog />, permission: "allow-manage-blog-articles" },
  { path: "/blogs/dashboard", component: () => <AsyncBlogsDashboard />, permission: "allow-manage-blog-articles" },
  { path: "/blogs/:id", component: () => <AsyncBlogEditor />, permission: "allow-manage-blog-articles" },
  { path: "/previewBlog/:id", component: ({ match }) => <AsyncPreviewBlog match={match} />, permission: "allow-manage-blog-articles" },
  { path: "/page-content", component: () => <AsyncPageContent />, permission: "allow-manage-page-content" },
  { path: "/tags-content", component: () => <AsyncTagsContent />, permission: "allow-manage-page-content" },
  { path: "/giveaways", component: () => <AsyncGiveaways />, permission: "allow-manage-giveaways" },
  { component: () => <AsyncNotFound /> },
];
const unauthorizedRoutes = [
  { path: "/", exact: true, component: () => <AsyncHome /> },
  { path: "/home", component: () => <AsyncHome /> },
  { path: "/authenticate", component: ({ onLogin }) => <AsyncAuthentication onLogin={onLogin} /> },
];
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
    };
  }
  login = async () => {
    this.setState({ isLoggedIn: true });
  }
  logout = async () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('user');
    window.location.replace('/authenticate')
  }
  getPermissions() {
    const { permissions, user } = this.state;
    if (!permissions || !user) {
      return false;
    }
    const rolePermissions = permissions.filter((item) => {
      const attachedRole = item.roles.find((role) => role.toString() === user.role.toString());
      if (attachedRole) {
        return true;
      }
    }).map((item) => item.id);
    const userPermissions = user.permissions.concat(rolePermissions);
    return new Set(userPermissions);
  }
  async componentDidMount() {
    if (localStorage.getItem('user')) {
      const roles = await getRoles();
      const permissions = await getPermissions();
      localStorage.setItem('roles', JSON.stringify(roles));
      localStorage.setItem('permissions', JSON.stringify(permissions));
      await updateUserInfo();
      const user = JSON.parse(localStorage.getItem('user'));
      this.setState({ permissions, user });
      this.login();

    } else if (!window.location.href.includes('/authenticate')) {
      window.location.replace('/authenticate')
    }
  }
  render() {
    const permissions = this.getPermissions();
    if (!this.state.isLoggedIn) {
      return (
        <Router>
          <div>
            <AsyncNavigation isLoggedIn={this.state.isLoggedIn} onLogout={this.logout} />
            <Switch>
              {
                unauthorizedRoutes.map((item, index) => (<Route key={index} path={item.path} exact={item.exact}
                  component={({ match }) => item.component({ match, onLogin: () => this.login })} />))
              }
            </Switch>
          </div>
        </Router>
      );
    }
    return (
      <Router>
        <div>
          <AsyncNavigation isLoggedIn={this.state.isLoggedIn} onLogout={this.logout} />
          <Switch>
            {
              authorizedRoutes.filter((item) => !item.permission || permissions.has(item.permission)).map((item, index) => (
                <Route key={index} path={item.path} exact={item.exact}
                  component={({ match }) => item.component({ match, onLogin: () => this.login })} />))
            }
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
